<script>
import ReturnPointsForm from './ReturnPointsForm'

export default {
  extends: ReturnPointsForm,

  methods: {
    onSubmit() {
      this.createReturnPoint({ ...this.form, delivery: this.deliveryTime })
        .then(() => {
          this.successSubmit({ message: 'Точка возврата успешно создана', routeName: 'return-points' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>